import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridPrintExportOptions,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

//EXEMPLO DE DATA GRID

// const columns: GridColDef[] = [
//   { field: 'id', headerName: 'ID', width: 70 },
//   { field: 'firstName', headerName: 'First name', width: 130 },
//   { field: 'lastName', headerName: 'Last name', width: 130 },
//   {
//     field: 'age',
//     headerName: 'Age',
//     type: 'number',
//     width: 90,
//   },
//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: (params: GridValueGetterParams) =>
//       `${params.row.firstName || ''} ${params.row.lastName || ''}`,
//   },
// ];

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

const localizedTextsMap = {
  columnMenuUnsort: "não classificado",
  columnMenuSortAsc: "Classificar por ordem crescente",
  columnMenuSortDesc: "Classificar por ordem decrescente",
  columnMenuFilter: "Filtro",
  columnMenuHideColumn: "Ocultar",
  columnMenuShowColumns: "Mostrar colunas",
  noRowsLabel: "Sem matriculados nessa turma!",
  noResultsOverlayLabel: "Sem dados encontrados! Erro na api!",
  columnHeaderFiltersLabel: "Mostrar filtros",
  columnHeaderSortIconLabel: "Ordenação",
  // Export selector toolbar button text
  toolbarExport: "Exportar",
  toolbarExportLabel: "Exportar",
  toolbarExportCSV: "Baixar em Excel",
  toolbarExportPrint: "Imprimir/Baixar em PDF",
  toolbarExportExcel: "Baixar como Excel",
};

interface TableProps {
  rows: any;
}


export default function DataTable(props: TableProps) {
  const columns: GridColDef[] = [
    {
      field: "pagamentoMatricula",
      headerName: "Pagamento/Matrícula",
      width: 200,
      align: "center",
      renderCell: (params) => (params.value ? "PAGO" : "NÃO CONFIRMADO"),
    },
    { field: "name", headerName: "Nome Completo", width: 400 },
    { field: "email", headerName: "Email", width: 300 },
    { field: "cpf", headerName: "CPF", width: 150, align: "center" },
    { field: "rg", headerName: "RG", width: 130, align: "center" },
    {
      field: "birthday",
      headerName: "Aniversário",
      type: "string",
      width: 150,
      align: "center",
    },
    {
      field: "celularAluno",
      headerName: "Celular",
      width: 150,
      align: "center",
    },
    {
      field: "celularResponsavel",
      headerName: "Celular Responsável",
      width: 150,
      align: "center",
    },
    { field: "cep", headerName: "CEP", width: 130, align: "center" },
    { field: "address", headerName: "Endereço Completo", width: 250 },
    { field: "cidade", headerName: "Cidade", width: 90, align: "center" },
    { field: "escola", headerName: "Escola", width: 200 },
    { field: "turmaDaEscola", headerName: "Série", width: 90, align: "center" },
    { field: "enem", headerName: "Fez ENEM?", width: 90, align: "center" },
    { field: "responsavel", headerName: "Nome do Responsável", width: 400 },
    {
      field: "cursoUniversidade",
      headerName: "Qual curso da universidade?",
      width: 200,
      align: "center",
    },
    {
      field: "cursoCode",
      headerName: "Código do curso",
      width: 130,
      align: "center",
    },
    {
      field: "cursoMatriculado",
      headerName: "Curso escolhido",
      width: 400,
      align: "center",
    },
    { field: "id", headerName: "ID", width: 300, align: "center" },
    {
      field: "rgResponsavel",
      headerName: "RG do Responsável",
      width: 130,
      align: "center",
    },
    {
      field: "cpfResponsavel",
      headerName: "CPF do Responsável",
      width: 150,
      align: "center",
    },
    {
      field: "cidadeDoCurso",
      headerName: "Cidade do Curso",
      width: 150,
      align: "center",
    },
    {
      field: "valorDoCurso",
      headerName: "Valor do Curso",
      width: 150,
      align: "center",
    },
    {
      field: "valorDoCursoFormatted",
      headerName: "Valor do Curso Formatado",
      width: 200,
      align: "center",
    },
    { field: "created", headerName: "Criado", width: 150, align: "center" },
    {
      field: "pacoteComprado",
      headerName: "Pacote Comprado",
      width: 150,
      align: "center",
    },
    {
      field: "pacoteCode",
      headerName: "Código do Pacote",
      width: 150,
      align: "center",
    },
    {
      field: "qtdPacotes",
      headerName: "Quantidade de Pacotes",
      width: 150,
      align: "center",
    },
    {
      field: "pagamentoPacotes",
      headerName: "Pagamento de Pacotes",
      width: 150,
      align: "center",
      renderCell: (params) => (params.value ? "PAGO" : "NÃO CONFIRMADO"),
    },
    {
      field: "janeiroPay",
      headerName: "Pago em Janeiro",
      width: 150,
      align: "center",
      renderCell: (params) => (params.value ? "PAGO" : "NÃO CONFIRMADO"),
    },
    {
      field: "fevereiroPay",
      headerName: "Pago em Fevereiro",
      width: 150,
      align: "center",
      renderCell: (params) => (params.value ? "PAGO" : "NÃO CONFIRMADO"),
    },
    {
      field: "marcoPay",
      headerName: "Pago em Março",
      width: 150,
      align: "center",
      renderCell: (params) => (params.value ? "PAGO" : "NÃO CONFIRMADO"),
    },
    {
      field: "abrilPay",
      headerName: "Pago em Abril",
      width: 150,
      align: "center",
      renderCell: (params) => (params.value ? "PAGO" : "NÃO CONFIRMADO"),
    },
    {
      field: "maioPay",
      headerName: "Pago em Maio",
      width: 150,
      align: "center",
      renderCell: (params) => (params.value ? "PAGO" : "NÃO CONFIRMADO"),
    },
    {
      field: "junhoPay",
      headerName: "Pago em Junho",
      width: 150,
      align: "center",
      renderCell: (params) => (params.value ? "PAGO" : "NÃO CONFIRMADO"),
    },
    {
      field: "julhoPay",
      headerName: "Pago em Julho",
      width: 150,
      align: "center",
      renderCell: (params) => (params.value ? "PAGO" : "NÃO CONFIRMADO"),
    },
    {
      field: "agostoPay",
      headerName: "Pago em Agosto",
      width: 150,
      align: "center",
      renderCell: (params) => (params.value ? "PAGO" : "NÃO CONFIRMADO"),
    },
    {
      field: "setembroPay",
      headerName: "Pago em Setembro",
      width: 150,
      align: "center",
      renderCell: (params) => (params.value ? "PAGO" : "NÃO CONFIRMADO"),
    },
    {
      field: "outubroPay",
      headerName: "Pago em Outubro",
      width: 150,
      align: "center",
      renderCell: (params) => (params.value ? "PAGO" : "NÃO CONFIRMADO"),
    },
    {
      field: "novembroPay",
      headerName: "Pago em Novembro",
      width: 150,
      align: "center",
      renderCell: (params) => (params.value ? "PAGO" : "NÃO CONFIRMADO"),
    },
    {
      field: "dezembroPay",
      headerName: "Pago em Dezembro",
      width: 150,
      align: "center",
      renderCell: (params) => (params.value ? "PAGO" : "NÃO CONFIRMADO"),
    },
    {
      field: "bloqueado",
      headerName: "Bloqueado",
      width: 150,
      align: "center",
      renderCell: (params) => (params.value ? "SIM" : "NÃO"),
    },
  ];

  const printOptions: GridPrintExportOptions = {
    hideFooter: true, 
    hideToolbar: true,
    includeCheckboxes: true,
    copyStyles: true,
    bodyClassName: 'custom-print-body',
    pageStyle: `
      @media print {
        @page {
          size: landscape; /* Define a orientação como paisagem */
          margin: 10mm; /* Adiciona uma margem ao redor da página */
        }
        body {
          transform: scale(0.70); /* Aplica a escala de 75% */
          transform-origin: top left;
          padding: 10mm; /* Adiciona padding ao corpo para espaçamento interno */
        }
        .MuiDataGrid-root {
          width: 100% !important; /* Calcula a largura para considerar as margens */
          max-width: 100vw; /* Considera as margens na largura máxima */
          height: auto !important; /* Ajusta a altura automaticamente */
          overflow: visible !important; /* Evita que o conteúdo seja cortado */
          page-break-inside: avoid; /* Evita quebras de página dentro do DataGrid */
          margin: auto; /* Centraliza a tabela */
          display: flex; /* Usa flexbox para garantir ajuste automático */
          flex-wrap: wrap; /* Permite que colunas se ajustem */
        }
        .MuiDataGrid-cell, .MuiDataGrid-colCell {
          white-space: nowrap; /* Impede quebra de linha dentro das células */
          overflow: hidden; /* Esconde o overflow para evitar conteúdo fora dos limites */
          text-overflow: ellipsis; /* Adiciona reticências no overflow */
        }
        .MuiDataGrid-colCell {
          max-width: none; /* Remove largura máxima das colunas */
          flex: 1; /* Permite que as colunas se ajustem proporcionalmente */
        }
        .MuiDataGrid-row {
          page-break-inside: avoid; /* Evita quebras de página no meio de uma linha */
        }
      }
    `,
  };
  function CustomToolbar() { 
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            delimiter: ";",
            utf8WithBom: true,
          }}
          printOptions={printOptions}
        />
      </GridToolbarContainer>
    );
  };
  

  return (
    <div
      className="table-data-students"
      style={{
        backgroundColor: "#fff",
        width: "100%",
        height: "auto",
        borderRadius: "8px",
        
      }}
    >

      <DataGrid
        autoHeight={true}
        rows={props.rows}
        columns={columns}
        // pageSize={25}
        //rowsPerPageOptions={[25, 50, 100, 500]}
        checkboxSelection={true}
        localeText={localizedTextsMap}
        // slots={{
        //   Toolbar: () => (
        //     <GridToolbarContainer>
        //       <GridToolbarExport 
        //       csvOptions={{
        //         delimiter: ';',
        //         utf8WithBom: true,
        //       }}
        //       />
        //     </GridToolbarContainer>
        //   ),
        // }}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}

/*
// Root
  noRowsLabel: 'No rows',
  noResultsOverlayLabel: 'No results found.',
  errorOverlayDefaultLabel: 'An error occurred.',

  // Density selector toolbar button text
  toolbarDensity: 'Density',
  toolbarDensityLabel: 'Density',
  toolbarDensityCompact: 'Compact',
  toolbarDensityStandard: 'Standard',
  toolbarDensityComfortable: 'Comfortable',

  // Columns selector toolbar button text
  toolbarColumns: 'Columns',
  toolbarColumnsLabel: 'Select columns',

  // Filters toolbar button text
  toolbarFilters: 'Filters',
  toolbarFiltersLabel: 'Show filters',
  toolbarFiltersTooltipHide: 'Hide filters',
  toolbarFiltersTooltipShow: 'Show filters',
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} active filters` : `${count} active filter`,

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: 'Search…',
  toolbarQuickFilterLabel: 'Search',
  toolbarQuickFilterDeleteIconLabel: 'Clear',

  // Export selector toolbar button text
  toolbarExport: 'Export',
  toolbarExportLabel: 'Export',
  toolbarExportCSV: 'Download as CSV',
  toolbarExportPrint: 'Print',
  toolbarExportExcel: 'Download as Excel',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Find column',
  columnsPanelTextFieldPlaceholder: 'Column title',
  columnsPanelDragIconLabel: 'Reorder column',
  columnsPanelShowAllButton: 'Show all',
  columnsPanelHideAllButton: 'Hide all',

  // Filter panel text
  filterPanelAddFilter: 'Add filter',
  filterPanelDeleteIconLabel: 'Delete',
  filterPanelLinkOperator: 'Logic operator',
  filterPanelOperators: 'Operator', // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: 'And',
  filterPanelOperatorOr: 'Or',
  filterPanelColumns: 'Columns',
  filterPanelInputLabel: 'Value',
  filterPanelInputPlaceholder: 'Filter value',

  // Filter operators text
  filterOperatorContains: 'contains',
  filterOperatorEquals: 'equals',
  filterOperatorStartsWith: 'starts with',
  filterOperatorEndsWith: 'ends with',
  filterOperatorIs: 'is',
  filterOperatorNot: 'is not',
  filterOperatorAfter: 'is after',
  filterOperatorOnOrAfter: 'is on or after',
  filterOperatorBefore: 'is before',
  filterOperatorOnOrBefore: 'is on or before',
  filterOperatorIsEmpty: 'is empty',
  filterOperatorIsNotEmpty: 'is not empty',
  filterOperatorIsAnyOf: 'is any of',

  // Filter values text
  filterValueAny: 'any',
  filterValueTrue: 'true',
  filterValueFalse: 'false',

  // Column menu text
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Show columns',
  columnMenuFilter: 'Filter',
  columnMenuHideColumn: 'Hide',
  columnMenuUnsort: 'Unsort',
  columnMenuSortAsc: 'Sort by ASC',
  columnMenuSortDesc: 'Sort by DESC',

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} active filters` : `${count} active filter`,
  columnHeaderFiltersLabel: 'Show filters',
  columnHeaderSortIconLabel: 'Sort',

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} rows selected`
      : `${count.toLocaleString()} row selected`,

  // Total row amount footer text
  footerTotalRows: 'Total Rows:',

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Checkbox selection',
  checkboxSelectionSelectAllRows: 'Select all rows',
  checkboxSelectionUnselectAllRows: 'Unselect all rows',
  checkboxSelectionSelectRow: 'Select row',
  checkboxSelectionUnselectRow: 'Unselect row',

  // Boolean cell text
  booleanCellTrueLabel: 'yes',
  booleanCellFalseLabel: 'no',

  // Actions cell more text
  actionsCellMore: 'more',

  // Column pinning text
  pinToLeft: 'Pin to left',
  pinToRight: 'Pin to right',
  unpin: 'Unpin',

  // Tree Data
  treeDataGroupingHeaderName: 'Group',
  treeDataExpand: 'see children',
  treeDataCollapse: 'hide children',

  // Grouping columns
  groupingColumnHeaderName: 'Group',
  groupColumn: (name) => `Group by ${name}`,
  unGroupColumn: (name) => `Stop grouping by ${name}`,

  // Master/detail
  detailPanelToggle: 'Detail panel toggle',
  expandDetailPanel: 'Expand',
  collapseDetailPanel: 'Collapse',

  // Used core components translation keys
  MuiTablePagination: {},

  // Row reordering text
  rowReorderingHeaderName: 'Row reordering',

  // Aggregation
  aggregationMenuItemHeader: 'Aggregation',
  aggregationFunctionLabelSum: 'sum',
  aggregationFunctionLabelAvg: 'avg',
  aggregationFunctionLabelMin: 'min',
  aggregationFunctionLabelMax: 'max',
  aggregationFunctionLabelSize: 'size',
};
*/
